.sd-Paroksha-one {
  height: 300px;
  font-family: "Barlow", sans-serif;

  .banner {
    display: flex;
    height: 250px;
    justify-content: space-between;
    align-items: center;
    background-repeat: no-repeat;
    padding: 0px 60px 0px 60px;
    background-size: cover;
    background-image: url("../../Assets/sd-parokshaseva-pngs/SD-parojshaseva-banner-bg.png");
    .sd-banner-top {
      width: 50%;
      display: flex;
      flex-direction: column;
      h1 {
        font-size: 37px;
        color: #fff;
        margin-bottom: 8px !important;
        line-height: 38px;
        letter-spacing: 0.2px;
        span {
          font-weight: 600;

          span {
            font-weight: 400;
            margin-left: 10px;
          }
        }
      }
      h4 {
        font-size: 17px;
        color: #fff;
        letter-spacing: 0.2px;
        font-weight: 400;
      }
    }
    .sd-banner-bottom {
      width: 50%;
      height: 100%;
      padding: 20px;
      // padding: 10px 0px 10px 0px;
      display: flex;
      align-items: center;
      img {
        // height: 95%;
        width: 100%;
        // padding: 10px;
        // height: 75%;
      }
    }
  }
}
.sd-Paroksha-two {
  font-family: "Barlow", sans-serif;
  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 60px 30px;
    h1 {
      font-size: 30px;
      color: #2f3193;
      padding-bottom: 10px;
      letter-spacing: 0px;
      font-weight: 400;
      span {
        font-weight: 600;
      }
    }
    img {
      height: 10px;
    }
    h4 {
      color: #181818;
    }
  }
  .bottom {
    height: 60%;
    padding: 0px 60px;
    line-height: 24px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #181818;
  }
}
.sd-Paroksha-three {
  font-family: "Barlow", sans-serif;

  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 60px 30px;
    h1 {
      font-size: 30px;
      color: #2f3193;
      padding-bottom: 10px;
      font-weight: 400;
      span {
        font-weight: 600;
      }
    }
    img {
      height: 10px;
    }
    h4 {
      color: #181818;
    }
  }
  .bottom {
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .seva-div {
      overflow: hidden;
      padding: 18px 18px 0;
      // margin-bottom: 30px;
      border: 1px solid #bfbfbf;
      border-radius: 4px;
      width: 380px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0px 20px 30px;
      .sd-copy {
        position: absolute;
        top: 20px;
        font-size: 18px;
        right: 47%;
        font-weight: 500;
        background-color: rgb(228, 252, 253);
        padding: 5px 15px;
        border-radius: 4px;
        animation: slow-message 1s;
        border: 1.5px solid #bfbfbf;
      }
      .head-image {
        width: 100%;
        height: 150px;
        border-radius: 4px;
        align-self: center;
      }
      h4 {
        text-align: center;
        color: #2f3193;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        padding: 15px 0 5px;
      }
      h5 {
        text-align: center;
        letter-spacing: 0.3px;
        font-size: 15px;
        font-weight: 400;
        color: #181818;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4; /* number of lines to show */
        -webkit-box-orient: vertical;
        line-height: 20px;
      }
      h6 {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 5px 0 16px;
        font-size: 15px;
        font-weight: 600;
        color: #f58220;
        margin-bottom: 0px;
        cursor: pointer;

        img {
          height: 11px;
          margin-left: 5px;
          // margin-top: 2px;
          filter: invert(1);
        }
      }
      .sd-timings {
        background-color: #e5e5e5;
        margin-left: -18px;
        width: 380px;
        padding: 0px 20px;
        div {
          img {
            height: 20px;
            width: 28px;
            cursor: pointer;

            padding: 4px;
          }
          .border-l {
            border-left: 1px solid grey;
            padding-left: 10px;
          }
          .padding-r {
            padding-right: 10px;
          }
        }
        h3 {
          display: flex;
          align-items: center;

          text-align: center;
          // margin-left: -18px;
          // margin-right: -18px !important;
          text-align: center;
          padding: 12px 0;
          font-weight: 700;
          opacity: 0.7;

          color: #181818;

          font-size: 14px;
          background-color: #e5e5e5;
          margin-bottom: 0px;
          img {
            height: 16px;
            margin-right: 8px;
          }
        }
      }

      div {
        display: flex;
        padding: 12px 0;
        justify-content: space-between;
        align-items: center;
        p {
          margin: 0px;
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 0px;
          color: #181818;
          display: flex;
          align-items: center;
          img {
            height: 14px;
            margin-right: 3px;
          }
        }
        button {
          color: white;
          background-color: #f58220;
          border: none;
          display: flex;
          align-self: center;
          align-items: center;
          border-radius: 3px;
          justify-content: center;
          font-size: 14px;
          padding: 6px 13px;
          white-space: nowrap;

          font-weight: 600;
          letter-spacing: 0.3px;
          .imgs {
            height: 11px;
            margin-left: 5px;
          }
        }
      }
    }
    .sd-pratyaksha-seva-div {
      overflow: hidden;
      padding: 25px 0px 25px 0;
      border-bottom: 1px solid #dddddd;
      width: 100%;
      display: flex;
      justify-content: space-between;
      // height: 150px;
      .sd-pratyaksha-seva-inner-left {
        width: 250px;
        height: 100%;
        img {
          width: 250px;
          height: 100%;
          border-radius: 4px;
          max-height: 115px;
        }
      }

      .sd-pratyaksha-seva-inner-middle {
        width: 70%;
        padding: 0px 60px 0px 20px;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        .sd-pratyaksha-seva-inner-middle-top {
          display: flex;
          align-items: center;
          padding-bottom: 10px;
          h4 {
            text-align: left;
            color: #2f3193;
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 0px;
            // padding: 15px 0 5px;
          }
          div {
            padding-left: 10px;
            img {
              height: 22px;
              width: 32px;
              padding: 4px;
              cursor: pointer;
            }
            .border-l {
              border-left: 1px solid #dddddd;
              padding-left: 10px;
            }

            .padding-r {
              padding-right: 5px;
              margin-right: 5px;
            }
          }
        }
        .sd-pratyaksha-seva-inner-middle-bottom {
          div {
            line-height: 24px;
            h5 {
              text-align: left;
              letter-spacing: 0.3px;
              font-size: 16px;
              font-weight: 400;
              color: #181818;

              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3; /* number of lines to show */
              -webkit-box-orient: vertical;
              line-height: 22px;
              margin-bottom: 0px;
            }
            span {
              align-items: center;
              justify-content: center;
              text-align: center;
              font-size: 16px;
              font-weight: 600;
              color: #f58220;
              margin-bottom: 0px;
              cursor: pointer;
              img {
                height: 11px;
                margin-left: 1px;
                margin-top: -2px;
              }
            }
          }
        }
      }
      .sd-pratyaksha-seva-inner-right {
        width: 15%;
        border-left: 1px solid #ededed;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .sd-timings {
          padding: 0px 20px;

          h3 {
            text-align: center;
            // margin-left: -18px;
            // margin-right: -18px !important;
            text-align: center;
            padding: 0px 0px 12px 0;
            font-weight: 500;
            opacity: 0.7;
            color: #181818;

            font-size: 15px;
            margin-bottom: 0px;
            i {
              margin-right: 8px;
            }
          }
        }
        p {
          margin: 0px;
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 0px;
          color: #181818;
          display: flex;
          align-items: center;

          img {
            height: 14px;
            margin-right: 3px;
          }
        }
        button {
          color: white;
          background-color: #f58220;
          border: none;
          display: flex;
          align-self: center;
          align-items: center;
          border-radius: 3px;
          justify-content: center;
          font-size: 14px;
          padding: 6px 13px;
          white-space: nowrap;

          font-weight: 600;
          letter-spacing: 0.3px;
          .imgs {
            height: 11px;
            margin-left: 5px;
          }
        }
      }
      .sd-copy {
        position: absolute;
        top: 20px;
        font-size: 18px;
        right: 47%;
        font-weight: 500;
        background-color: rgb(228, 252, 253);
        padding: 5px 15px;
        border-radius: 4px;
        animation: slow-message 0.5s;
        border: 1.5px solid #bfbfbf;
      }
    }
    .youtube-video {
      width: 800px;
      height: 52vh;
      border-radius: 4px;
      margin: 0px 20px 30px;

      background-image: url("../../Assets/sd-home-pngs/SD-srisaila-tv-bg.png");
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
      background-color: #2f3293;
      display: flex;
      flex-direction: column;
      align-items: center;
      Iframe {
        width: 600px;
        height: 58%;
      }
      div {
        display: flex;
        align-items: center;
        margin: 20px;
        h2 {
          display: flex;
          font-weight: 600;
          letter-spacing: 0.2px;
          font-size: 30px;
          padding: 0 15px;
          margin: 25px 0;
          color: white;
          align-items: center;
          border-right: 1px solid #a39f9f;
        }
        h3 {
          display: flex;
          font-weight: 400;
          letter-spacing: 0.2px;
          font-size: 30px;
          padding: 0 15px;
          margin: 25px 0;
          color: white;
          align-items: center;
        }
      }
    }
    .border-top {
      border-top: 1px solid #dddddd;
    }
  }
}
.sd-Paroksha-four {
  font-family: "Barlow", sans-serif;
  margin-bottom: 40px;
  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 60px 40px;
    h1 {
      font-size: 30px;
      color: #2f3193;
      padding-bottom: 10px;
      font-weight: 400;
      span {
        font-weight: 600;
      }
    }
    img {
      height: 10px;
    }
  }
  .bottom {
    padding: 0px 60px;

    .accordion {
      .selective-card {
        border-right: none;
        border-left: none;
        background-color: white;
        .heading {
          padding: 10px 0px;
          background-color: white;
          border-bottom: none;
          .top-head {
            align-items: center;
            justify-content: space-between;
            display: flex;

            .top-head-top {
              display: flex;
              align-items: center;
              h3 {
                text-align: center;
                color: #f58220;
                border-radius: 50%;
                margin: 0 14px 0 0;
                width: 26px;
                font-size: 18px;
                font-weight: 500;
                border: 1px solid #f58220;
                padding: 2px 8px;
              }
              h2 {
                margin-right: 10px;
                font-size: 18px;
                color: #2f3193;
                letter-spacing: 0.2px;
                font-weight: 600;
                margin: 0px;
              }
            }
            .custom-h2 {
              margin: 0px;
              .cutombuttom {
                outline: none;
                box-shadow: none;
                border: none;
                i {
                  font-weight: 700 !important;
                  font-size: 18px !important;
                  color: #999;
                }
                // i {
                //   font-size: 18px;
                //   filter: invert(0.5);
                // }
              }
            }
          }
        }
        .card-body {
          font-size: 18px;
          // height: 50px;
          margin-top: -20px;
          padding: 10px 38px 20px;
          animation: slow 0.3s;
        }
      }
      .b-none {
        border-top: 2px solid #efefef;
        border-bottom: 2px solid #efefef;
      }
    }
  }
}

@keyframes slow {
  0% {
    opacity: 0;
    height: 0%;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}
@keyframes slow-message {
  0% {
    margin-top: -100%;
  }
  100% {
    margin-top: 0%;
  }
}
@media only screen and (min-width: 1827px) {
  .sd-Paroksha-one {
    .banner {
      .sd-banner-bottom {
        padding: 20px;
        img {
          height: 100%;
        }
      }
    }
  }
  .sd-Paroksha-three {
    padding: 30px;
    .bottom {
      justify-content: center;
      .seva-div {
        width: 400px;
        .sd-timings {
          width: 400px;
        }
      }
      .youtube-video {
        width: 820px;
        height: 52.2vh;
        justify-content: center;
        margin-top: 10px;
        border-radius: 4px;
        div {
          width: 100%;
          place-content: center;
          margin-top: -30px;
          // h2 {
          //   font-size: 18px;
          // }
          // h3 {
          //   font-size: 18px;
          // }
        }
        img {
          height: 65%;
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1827px) {
  .sd-Paroksha-three {
    padding: 30px;
    .bottom {
      justify-content: center;
      .seva-div {
        width: 400px;
        .sd-timings {
          width: 400px;

          // width: 28.8vw;
        }
      }
      .youtube-video {
        width: 840px;
        height: 52.2vh;
        justify-content: center;
        margin-top: 10px;
        border-radius: 4px;
        div {
          width: 100%;
          place-content: center;
          margin-top: -30px;
          // h2 {
          //   font-size: 18px;
          // }
          // h3 {
          //   font-size: 18px;
          // }
        }
        img {
          height: 65%;
        }
      }
    }
  }
  .sd-Paroksha-one {
    .banner {
      .sd-banner-bottom {
        width: 50%;
        padding: 20px;
      }
    }
  }
}

@media only screen and (max-width: 1300px) and (min-width: 900px) {
  .sd-Paroksha-three {
    padding: 30px;

    .bottom {
      .seva-div {
        width: 350px;
        .sd-timings {
          width: 350px;
          // margin-right: -6%;
        }
      }
      .sd-pratyaksha-seva-div {
        .sd-pratyaksha-seva-inner-middle-top {
          justify-content: space-between;
          div {
            display: flex;
          }
        }
        .sd-pratyaksha-seva-inner-right {
          width: 20%;

          .sd-timings {
            padding: 0px 20px;

            h3 {
              padding: 0px 0px 12px 0;
              flex-wrap: wrap;
              font-size: 15px;
              margin-bottom: 0px;
              i {
                margin-right: 8px;
              }
            }
          }
          p {
            font-size: 20px;

            .fa {
              font-weight: 600;
              font-size: 14px;
              color: grey;
            }
          }
        }
      }
      .youtube-video {
        width: 740px;
        div {
          margin: 20px;
        }
        Iframe {
          width: 570px;
          height: 60%;
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) and (min-width: 650px) {
  .sd-Paroksha-one {
    .banner {
      .sd-banner-top {
        width: 100%;
        justify-content: center;
        align-items: center;
        h1 {
          text-align: center;
        }
      }
      .sd-banner-bottom {
        display: none;
      }
    }
  }
  .sd-Paroksha-three {
    padding: 30px;
    .top {
      h1 {
      }
    }
    .bottom {
      padding-left: 30px;
      padding-right: 30px;

      .seva-div {
        width: 440px;
        margin-bottom: 20px;
        .head-image {
          height: 180px;
        }
        .sd-timings {
          width: 440px;
          // width: 118%;
          // width: 118%;
          // margin-left: -8%;
          h3 {
            font-size: 13px;
          }
        }
      }
      .sd-pratyaksha-seva-div {
        .sd-pratyaksha-seva-inner-left {
          width: 150px;
          height: 100%;
          img {
            width: 150px;
            height: 100%;
            border-radius: 4px;
          }
        }
        .sd-pratyaksha-seva-inner-middle {
          width: 65%;
          padding: 0px 30px 0px 20px;

          // justify-content: space-between;
          .sd-pratyaksha-seva-inner-middle-top {
            justify-content: space-between;

            h4 {
              font-size: 18px;
            }
            div {
              display: flex;
            }
          }
          .sd-pratyaksha-seva-inner-middle-bottom {
            div {
              h5 {
                text-align: left;
                letter-spacing: 0.3px;
                font-size: 16px;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3; /* number of lines to show */
                -webkit-box-orient: vertical;
                line-height: 22px;
                margin-bottom: 0px;
              }
              span {
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 16px;
                font-weight: 600;
                color: #f58220;
                margin-bottom: 0px;
                img {
                  height: 11px;
                  margin-left: 1px;
                  margin-top: -2px;
                }
              }
            }
          }
        }
      }
      .youtube-video {
        width: 440px;
        height: 350px;
        margin: 0px;
        padding-top: 10px;
        margin-top: 10px;
        justify-content: center;

        div {
          width: 100%;
          place-content: center;
          margin-top: -30px;
          h2 {
            font-size: 21px;
            margin: 5px;
          }
          h3 {
            font-size: 21px;
            margin: 5px;
          }
        }
        Iframe {
          width: 350px;
          height: 60%;
        }
      }
    }
  }
}

@media only screen and (min-width: 420px) and (max-width: 650px) {
  .sd-Paroksha-one {
    height: 250px;
    .banner {
      height: 200px;
      .sd-banner-top {
        width: 100%;
        justify-content: center;
        align-items: center;
        h1 {
          text-align: center;
          font-size: 26px;
        }
        h4 {
          text-align: center;
        }
      }
      .sd-banner-bottom {
        display: none;
      }
    }
  }
  .sd-Paroksha-three {
    .bottom {
      justify-content: center;
      padding: 30px;
      .seva-div {
        .head-image {
          height: 120px;
        }
        width: 320px;
        margin-bottom: 30px;
        .sd-timings {
          width: 320px;
          // width: 110%;
          // margin-left: -5%;
        }
      }
      .sd-pratyaksha-seva-div {
        overflow: hidden;
        padding: 25px 0px 25px 0;
        flex-direction: column;
        .sd-pratyaksha-seva-inner-left {
          display: none;
        }
        .sd-pratyaksha-seva-inner-middle {
          width: 100%;
          padding: 0px 10px 0px 10px;

          // justify-content: space-between;
          .sd-pratyaksha-seva-inner-middle-top {
            justify-content: space-between;
            display: flex;
            h4 {
              font-size: 17px;
            }
            div {
              display: flex;
            }
          }
          .sd-pratyaksha-seva-inner-middle-bottom {
            div {
              h5 {
                text-align: left;
                letter-spacing: 0.3px;
                font-size: 15px;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3; /* number of lines to show */
                -webkit-box-orient: vertical;
                line-height: 20px;
                margin-bottom: 0px;
              }
              span {
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 15px;
                font-weight: 600;
                color: #f58220;
                margin-bottom: 0px;
                img {
                  height: 11px;
                  margin-left: 1px;
                  margin-top: -2px;
                }
              }
            }
          }
        }
        .sd-pratyaksha-seva-inner-right {
          width: 100%;
          border: none;
          flex-direction: row;
          justify-content: space-between;
          .sd-timings {
            padding-left: 10px;

            h3 {
              padding-bottom: 0px;
            }
          }
          p {
            padding-right: 10px;
          }
        }
      }
      .youtube-video {
        width: 320px;
        height: 300px;
        margin: 10px;

        div {
          width: 100%;
          place-content: center;
          margin: 10px;
          h2 {
            font-size: 18px;
          }
          h3 {
            font-size: 18px;
          }
        }
        Iframe {
          width: 280px;
          height: 48%;
        }
      }
    }
  }
  .sd-Paroksha-four {
    .top {
      padding: 20px;
    }
    .bottom {
      padding: 4.3vw;
      .accordion {
        .selective-card {
          .heading {
            .top-head {
              .top-head-top {
                h3 {
                  font-size: 13px;
                  width: 22px;
                  padding: 2px 5px;
                }
                h2 {
                  font-size: 12px;
                }
              }
              .custom-h2 {
                .custumbutton {
                  white-space: nowrap;
                }
              }
            }
          }
          .card-body {
            font-size: 12px;
            padding-left: 34px;
          }
        }
      }
    }
  }
  .sd-Paroksha-two {
    .top {
      h1 {
        text-align: center;
        font-size: 20px;
      }
    }
    .bottom {
      padding: 0 20px;
    }
  }
}
@media only screen and (max-width: 420px) {
  .sd-Paroksha-one {
    height: 250px;

    .banner {
      height: 200px;

      .sd-banner-top {
        width: 100%;
        justify-content: center;
        align-items: center;
        h1 {
          text-align: center;
          font-size: 24px;
          line-height: 26px;
        }
        h4 {
          text-align: center;
        }
      }
      .sd-banner-bottom {
        display: none;
      }
    }
  }
  .sd-Paroksha-three {
    .top {
      padding: 10px;
    }
    .bottom {
      justify-content: center;
      padding: 20px;
      .sd-pratyaksha-seva-div {
        overflow: hidden;
        padding: 25px 0px 25px 0;
        flex-direction: column;
        .sd-pratyaksha-seva-inner-left {
          display: none;
        }
        .sd-pratyaksha-seva-inner-middle {
          width: 100%;
          padding: 0px 10px 0px 10px;

          // justify-content: space-between;
          .sd-pratyaksha-seva-inner-middle-top {
            justify-content: space-between;
            display: flex;
            h4 {
              font-size: 17px;
            }
            div {
              display: flex;
            }
          }
          .sd-pratyaksha-seva-inner-middle-bottom {
            div {
              h5 {
                text-align: left;
                letter-spacing: 0.3px;
                font-size: 15px;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3; /* number of lines to show */
                -webkit-box-orient: vertical;
                line-height: 20px;
                margin-bottom: 0px;
              }
              span {
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 15px;
                font-weight: 600;
                color: #f58220;
                margin-bottom: 0px;
                img {
                  height: 11px;
                  margin-left: 1px;
                  margin-top: -2px;
                }
              }
            }
          }
        }
        .sd-pratyaksha-seva-inner-right {
          width: 100%;
          border: none;
          flex-direction: row;
          justify-content: space-between;
          .sd-timings {
            padding-left: 10px;

            h3 {
              padding-bottom: 0px;
            }
          }
          p {
            padding-right: 10px;
          }
        }
      }
      .seva-div {
        width: 270px;
        .head-image {
          height: 120px;
        }
        .sd-timings {
          width: 270px;
        }
      }
      .youtube-video {
        width: 270px;
        height: 200px;
        margin: 10px;

        div {
          width: 100%;
          place-content: center;
          margin: 0px;
          h2 {
            font-size: 13px;
          }
          h3 {
            font-size: 13px;
          }
        }
        Iframe {
          width: 250px;
          height: 50%;
        }
      }
    }
  }
  .sd-Paroksha-two {
    padding: 20px;
    .top {
      padding: 10px;
      h1 {
        font-size: 24px;
        text-align: center;
      }
    }
    .bottom {
      padding: 0px;
      padding-top: 20px;
    }
  }
}
