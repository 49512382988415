.sd-gp-one {
  font-family: "Barlow", sans-serif;
  -webkit-overflow-scrolling: touch;

  .sd-first-banner {
    padding: 60px;

    .inner {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fef1e7;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      p {
        text-align: center;
        font-size: 30px;
        width: 65%;
        padding: 60px;
        color: #181818;
      }
      .inner-image {
        position: absolute;
        height: 92%;
        width: 98%;
      }
    }
  }
  .sd-second-banner {
    padding: 60px;

    .inner {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fef1e7;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      p {
        text-align: center;
        font-size: 27px;
        width: 85%;
        color: #181818;

        padding: 60px;
      }
      .inner-image {
        position: absolute;
        height: 90%;
        width: 98%;
      }
    }
  }
  .t-1 {
    .top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-size: 30px;
        color: #2f3193;
        padding-bottom: 10px;
        letter-spacing: 0;
        font-weight: 400;
        span {
          font-weight: 600;
        }
      }
      img {
        height: 10px;
      }
      padding: 0px 40px 60px 30px;
    }
    .bottom {
      padding: 0px 60px;
      line-height: 24px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #181818 !important;
      text-align: center;

      p {
        color: #181818;
      }
    }
  }
  .t-2 {
    // height: 40vh;
    .t-2-top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px 40px 60px 30px;

      h1 {
        font-size: 30px;
        color: #2f3193;
        padding-bottom: 10px;
        letter-spacing: 0;
        font-weight: 400;
        text-align: center;
        span {
          font-weight: 600;
        }
      }
      img {
        height: 10px;
      }
    }
    .t-2-bottom {
      padding: 0px 60px;
      line-height: 24px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #181818;
      text-align: center;
      p {
        color: #181818;
      }
    }
  }
  .t-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;

    img {
      height: 85px;
      padding: 25px;
    }
    h3 {
      font-size: 30px;
      color: #2f3193;
      font-weight: 400;
      text-align: center;
    }
  }
}
.sd-gp-two {
  font-family: "Barlow", sans-serif;
  display: flex;
  justify-content: center;
  -webkit-overflow-scrolling: touch;

  .sd-gp-seva-div {
    padding-top: 20px;
    margin-right: 60px;

    border: 1px solid #f58220;
    overflow: hidden;
    width: 380px;
    display: flex;
    flex-direction: column;
    .head-image {
      width: 90%;
      height: 150px;
      border-radius: 4px;
      align-self: center;
    }
    h4 {
      width: 100%;
      text-align: center;
      color: #2f3193;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      padding: 15px 20px;
    }
    h5 {
      text-align: center;
      letter-spacing: 0.2px;
      font-size: 16px;
      font-weight: 400;
      padding-right: 25px;
      padding-left: 25px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      -webkit-box-orient: vertical;
      margin-bottom: 0px;
    }
    h6 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 16px;
      color: #181818;
      margin-bottom: 0;
      padding: 25px 25px;
      span {
        font-weight: 600;
        padding-top: 8px;
        font-size: 20px;
      }

      img {
        height: 18px;
        width: 5%;
        margin-left: 3px;
        margin-top: 2px;
        filter: invert(1);
      }
    }
    .sd-timings-gp {
      background-color: #e5e5e5;
      width: 380px;
      padding: 0px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        background-color: #e5e5e5;
        padding: 0px;
        img {
          height: 20px;
          width: 28px;
          padding: 4px;
          cursor: pointer;
        }
        .border-l {
          border-left: 1px solid grey;
          padding-left: 10px;
        }
        .padding-r {
          padding-right: 10px;
        }
      }
      h3 {
        text-align: center;
        // margin-left: -18px;
        // margin-right: -18px !important;
        text-align: center;
        padding: 12px 0;
        font-weight: 500;
        opacity: 0.7;
        font-size: 14px;
        color: #181818;
        background-color: #e5e5e5;
        margin-bottom: 0px;
        img {
          height: 16px;
          margin-right: 8px;
        }
      }
    }
    .sd-booknow {
      padding: 0px 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 380px;
      background-color: #f58220;

      p {
        padding: 14px 18px;
        margin: 0;
        padding-left: 0px;
        font-size: 22px;
        font-weight: 500;
        color: white;
        i {
          font-size: 15px;
          font-weight: 600;
        }
        img {
          height: 13px;
          filter: brightness(100);
        }
      }
      button {
        white-space: nowrap;

        color: #2f3293;
        background-color: #ffffff;
        border: none;
        display: flex;
        align-self: center;
        align-items: center;
        font-size: 14px;
        padding: 6px 13px;
        font-weight: 600;
        letter-spacing: 0.2px;
        justify-content: center;
        i {
          margin-left: 5px;
          width: 10%;
          font-size: 16px;
        }
      }
    }
    .sd-copy {
      position: absolute;
      top: 20px;
      font-size: 18px;
      right: 47%;
      font-weight: 500;
      background-color: rgb(228, 252, 253);
      padding: 5px 15px;
      border-radius: 4px;
      animation: slow-message 0.5s;
      border: 1.5px solid #bfbfbf;
    }
  }
  .sd-gp-video {
    width: 700px;
    background-image: url("../../Assets/sd-home-pngs/SD-srisaila-tv-bg.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    background-color: #2f3293;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    Iframe {
      width: 600px;
      height: 60%;
    }

    h2 {
      margin-top: -40px !important;
      width: 80%;
      display: flex;
      font-weight: 600;
      letter-spacing: 0.2px;
      font-size: 30px;
      padding: 30px 0px 30px 0px;
      color: white;
      align-items: center;
      display: flex;
      justify-content: center;
      h3 {
        font-weight: 400;
        align-items: center;
        margin-bottom: 0px;
        color: white;
      }
      span {
        color: grey;
        width: 1px;
        font-weight: 200;
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
  .sd-copy {
    position: absolute;
    top: 20px;
    font-size: 18px;
    right: 47%;
    font-weight: 500;
    background-color: rgb(228, 252, 253);
    padding: 5px 15px;
    border-radius: 4px;
    animation: slow-message 0.5s;
    border: 1.5px solid #bfbfbf;
  }
}
@keyframes slow-message {
  0% {
    margin-top: -100%;
  }
  100% {
    margin-top: 0%;
  }
}
@media only screen and (min-width: 1800px) {
}
@media only screen and (max-width: 1300px) and (min-width: 900px) {
  .sd-gp-one {
    .sd-first-banner {
      .inner {
        p {
          font-size: 20px;
        }
      }
    }
    .sd-second-banner {
      .inner {
        p {
          font-size: 20px;
        }
      }
    }
    .t-1 {
      .top {
        padding: 0px 40px 30px 30px;
      }
      .t-2 {
        .t-2-top {
          padding: 0px 40px 30px 30px;

          h1 {
            font-size: 24px;
            text-align: center;
          }
        }
        .t-2-bottom {
        }
      }
      .t-3 {
        padding: 0px 40px 30px 30px;

        img {
        }
        h3 {
          font-size: 24px;
        }
      }
    }
  }
  .sd-gp-two {
    .sd-gp-seva-div {
      width: 300px;
      margin-right: 20px;
      .head-image {
        height: 130px;
      }
      h6 {
        padding: 10px;
        padding-bottom: 15px;
        span {
          font-size: 16px;
        }
      }
      .sd-timings-gp {
        width: 300px;
      }
      .sd-booknow {
        width: 300px;
        p {
          padding: 10px 14px;
          font-size: 20px;
        }
      }
    }
    .sd-gp-video {
      width: 52vw;
      Iframe {
        width: 400px;
        height: 40%;
      }
      h2 {
        font-size: 24px;
        h3 {
          font-size: 24px;
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) and (min-width: 600px) {
  .sd-gp-one {
    .sd-first-banner {
      padding: 30px;

      .inner {
        p {
          font-size: 14px;
          padding: 30px;
        }
      }
    }
    .sd-second-banner {
      .inner {
        p {
          font-size: 14px;
          padding: 30px;
        }
      }
    }
    .t-1 {
      .top {
        padding: 0px 40px 30px 30px;
        h1 {
          text-align: center;
        }
      }
    }
    .t-2 {
      .t-2-top {
        padding: 0px 40px 30px 30px;

        h1 {
          font-size: 18px;
          text-align: center;
        }
      }
      .t-2-bottom {
      }
    }
    .t-3 {
      padding: 0px 40px 30px 30px;

      img {
      }
      h3 {
        font-size: 18px;
        text-align: center;
      }
    }
  }
  .sd-gp-two {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .sd-gp-seva-div {
      width: 500px;
      margin-right: 0px;
      .head-image {
        height: 200px;
      }
      h6 {
        span {
          font-size: 18px;
        }
      }
      .sd-timings-gp {
        width: 100%;
      }
      .sd-booknow {
        width: 100%;
      }
    }
    .sd-gp-video {
      width: 86.6%;
      margin-top: 30px;
      padding: 30px;
      Iframe {
        width: 70%;
      }
      h2 {
        font-size: 24px;
        h3 {
          font-size: 24px;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .sd-gp-one {
    .sd-first-banner {
      padding: 20px;

      .inner {
        p {
          font-size: 12px;
          padding: 10px;
          margin-bottom: 0px;
        }
        .inner-image {
          width: 95%;
          display: none;
        }
      }
    }
    .sd-second-banner {
      padding: 20px;

      .inner {
        p {
          font-size: 12px;
          padding: 20px;
        }
        .inner-image {
          width: 95%;
        }
      }
    }
    .t-1 {
      padding-top: 0px;
      .top {
        padding: 0px 20px 10px 20px;
        h1 {
          text-align: center;
          font-size: 20px;
        }
      }
      .bottom {
        padding: 0px 20px 10px 20px;
        line-height: 1.5;

        font-size: 12px;
      }
    }
    .t-2 {
      .t-2-top {
        padding: 0px 20px 10px 20px;

        h1 {
          font-size: 14px;
          text-align: center;
        }
      }
      .t-2-bottom {
        padding: 0px 20px 10px 20px;

        font-size: 12px;
        line-height: 1.5;
      }
    }
    .t-3 {
      padding: 0px 20px 10px 20px;

      img {
        height: 70px;
      }
      h3 {
        font-size: 16px;
        text-align: center;
      }
    }
  }
  .sd-gp-two {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .sd-gp-seva-div {
      width: 380px;
      margin-right: 0px;
      h4 {
        font-size: 14px;
        line-height: 1.5;
      }
      h5 {
        line-height: 1.5;
        font-size: 13px;
      }
      h6 {
        span {
          font-size: 16px;
        }
      }
      .sd-timings-gp {
        width: 100%;
      }
      .sd-booknow {
        width: 100%;
      }
    }
    .sd-gp-video {
      width: 89.6%;
      margin-top: 30px;
      padding: 30px;
      Iframe {
        width: 80%;
      }
      h2 {
        font-size: 14px;
        padding-bottom: 10px;
        h3 {
          font-size: 14px;
        }
        span {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}
@media only screen and (max-width: 420px) {
  .sd-gp-two {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .sd-gp-seva-div {
      width: 290px;
      margin-right: 0px;
      .head-image {
        height: 120px;
      }
      h4 {
        font-size: 18px;
        line-height: 1.5;
        padding: 7px;
        margin-bottom: 0px;
      }
      h5 {
        line-height: 1.3;
        font-size: 14px;
        margin-bottom: 0px;
      }
      h6 {
        padding: 5px;
        padding-bottom: 15px;
        span {
          font-size: 14px;
        }
      }
      .sd-timings-gp {
        width: 100%;
      }
      .sd-booknow {
        width: 100%;
        p {
          font-size: 18px;
        }
      }
    }
    .sd-gp-video {
      width: 89.6%;
      margin-top: 30px;
      padding: 30px;
      Iframe {
        width: 100%;
      }
      h2 {
        width: 90%;
        font-size: 14px;
        padding-bottom: 10px;
        h3 {
          font-size: 14px;
        }
        span {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}
