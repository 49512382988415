.sd-pratyaksha-seva-div {
  overflow: hidden;
  padding: 25px 0px 25px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  // height: 150px;
  padding: 0px 60px;
  .sd-pratyaksha-seva-inner-left {
    width: 250px;
    height: 100%;
    img {
      width: 250px;
      height: 100%;
      border-radius: 4px;
      max-height: 115px;
    }
  }

  .sd-pratyaksha-seva-inner-middle {
    width: 70%;
    padding: 0px 60px 0px 20px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    .sd-pratyaksha-seva-inner-middle-top {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      h4 {
        text-align: left;
        color: #2f3193;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 0px;
        // padding: 15px 0 5px;
      }
      div {
        padding-left: 10px;
        img {
          height: 22px;
          width: 32px;
          padding: 4px;
          cursor: pointer;
        }
        .border-l {
          border-left: 1px solid #dddddd;
          padding-left: 10px;
        }

        .padding-r {
          padding-right: 5px;
          margin-right: 5px;
        }
      }
    }
    .sd-pratyaksha-seva-inner-middle-bottom {
      div {
        line-height: 24px;
        h5 {
          text-align: left;
          letter-spacing: 0.3px;
          font-size: 16px;
          font-weight: 400;
          color: #181818;

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* number of lines to show */
          -webkit-box-orient: vertical;
          line-height: 22px;
          margin-bottom: 0px;
        }
        span {
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          color: #f58220;
          margin-bottom: 0px;
          cursor: pointer;
          img {
            height: 11px;
            margin-left: 1px;
            margin-top: -2px;
          }
        }
      }
    }
  }
  .sd-pratyaksha-seva-inner-right {
    width: 15%;
    border-left: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .sd-timings {
      padding: 0px 20px;

      h3 {
        text-align: center;
        // margin-left: -18px;
        // margin-right: -18px !important;
        text-align: center;
        padding: 0px 0px 12px 0;
        font-weight: 500;
        opacity: 0.7;
        color: #181818;

        font-size: 15px;
        margin-bottom: 0px;
        i {
          margin-right: 8px;
        }
      }
    }
    p {
      margin: 0px;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0px;
      color: #181818;
      display: flex;
      align-items: center;

      img {
        height: 14px;
        margin-right: 3px;
      }
    }
    button {
      color: white;
      background-color: #f58220;
      border: none;
      display: flex;
      align-self: center;
      align-items: center;
      border-radius: 3px;
      justify-content: center;
      font-size: 14px;
      padding: 6px 13px;
      white-space: nowrap;

      font-weight: 600;
      letter-spacing: 0.3px;
      .imgs {
        height: 11px;
        margin-left: 5px;
      }
    }
  }
  .sd-copy {
    position: absolute;
    top: 20px;
    font-size: 18px;
    right: 47%;
    font-weight: 500;
    background-color: rgb(228, 252, 253);
    padding: 5px 15px;
    border-radius: 4px;
    animation: slow-message 0.5s;
    border: 1.5px solid #bfbfbf;
  }
}

@keyframes slow {
  0% {
    opacity: 0;
    height: 0%;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}
@keyframes slow-message {
  0% {
    margin-top: -100%;
  }
  100% {
    margin-top: 0%;
  }
}
@media only screen and (min-width: 1827px) {
  .sd-Paroksha-three {
    padding: 30px;
    .bottom {
      justify-content: center;
      .seva-div {
        width: 400px;
        .sd-timings {
          width: 400px;
        }
      }
      .youtube-video {
        width: 820px;
        height: 52.2vh;
        justify-content: center;
        margin-top: 10px;
        border-radius: 4px;
        div {
          width: 100%;
          place-content: center;
          margin-top: -30px;
          // h2 {
          //   font-size: 18px;
          // }
          // h3 {
          //   font-size: 18px;
          // }
        }
        img {
          height: 65%;
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1827px) {
  .sd-Paroksha-three {
    padding: 30px;
    .bottom {
      justify-content: center;
      .seva-div {
        width: 400px;
        .sd-timings {
          width: 400px;

          // width: 28.8vw;
        }
      }
      .youtube-video {
        width: 840px;
        height: 52.2vh;
        justify-content: center;
        margin-top: 10px;
        border-radius: 4px;
        div {
          width: 100%;
          place-content: center;
          margin-top: -30px;
          // h2 {
          //   font-size: 18px;
          // }
          // h3 {
          //   font-size: 18px;
          // }
        }
        img {
          height: 65%;
        }
      }
    }
  }
  .sd-Paroksha-one {
    .banner {
      .sd-banner-bottom {
        width: 50%;
        padding: 20px;
      }
    }
  }
}

@media only screen and (max-width: 1300px) and (min-width: 900px) {
  .sd-pratyaksha-seva-div {
    .sd-pratyaksha-seva-inner-middle-top {
      justify-content: space-between;
      div {
        display: flex;
      }
    }
    .sd-pratyaksha-seva-inner-right {
      width: 20%;

      .sd-timings {
        padding: 0px 20px;

        h3 {
          padding: 0px 0px 12px 0;
          flex-wrap: wrap;
          font-size: 15px;
          margin-bottom: 0px;
          i {
            margin-right: 8px;
          }
        }
      }
      p {
        font-size: 20px;

        .fa {
          font-weight: 600;
          font-size: 14px;
          color: grey;
        }
      }
    }
  }
}
@media only screen and (max-width: 900px) and (min-width: 650px) {
  .sd-pratyaksha-seva-div {
    .sd-pratyaksha-seva-inner-left {
      width: 150px;
      height: 100%;
      img {
        width: 150px;
        height: 100%;
        border-radius: 4px;
      }
    }
    .sd-pratyaksha-seva-inner-middle {
      width: 50%;
      padding: 0px 30px 0px 20px;

      // justify-content: space-between;
      .sd-pratyaksha-seva-inner-middle-top {
        justify-content: space-between;

        h4 {
          font-size: 18px;
        }
        div {
          display: flex;
        }
      }
      .sd-pratyaksha-seva-inner-middle-bottom {
        div {
          h5 {
            text-align: left;
            letter-spacing: 0.3px;
            font-size: 16px;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
            -webkit-box-orient: vertical;
            line-height: 22px;
            margin-bottom: 0px;
          }
          span {
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            color: #f58220;
            margin-bottom: 0px;
            img {
              height: 11px;
              margin-left: 1px;
              margin-top: -2px;
            }
          }
        }
      }
    }
    .sd-pratyaksha-seva-inner-right {
      width: 30%;
    }
  }
}

@media only screen and (min-width: 420px) and (max-width: 650px) {
  .sd-pratyaksha-seva-div {
    overflow: hidden;
    padding: 0px 25px 0px 25px;
    flex-direction: column;
    .sd-pratyaksha-seva-inner-left {
      display: none;
    }
    .sd-pratyaksha-seva-inner-middle {
      width: 100%;
      padding: 0px 10px 0px 10px;

      // justify-content: space-between;
      .sd-pratyaksha-seva-inner-middle-top {
        justify-content: space-between;
        display: flex;
        h4 {
          font-size: 17px;
        }
        div {
          display: flex;
        }
      }
      .sd-pratyaksha-seva-inner-middle-bottom {
        div {
          h5 {
            text-align: left;
            letter-spacing: 0.3px;
            font-size: 15px;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
            -webkit-box-orient: vertical;
            line-height: 20px;
            margin-bottom: 0px;
          }
          span {
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 15px;
            font-weight: 600;
            color: #f58220;
            margin-bottom: 0px;
            img {
              height: 11px;
              margin-left: 1px;
              margin-top: -2px;
            }
          }
        }
      }
    }
    .sd-pratyaksha-seva-inner-right {
      margin-top: 10px;
      width: 100%;
      border: none;
      flex-direction: row;
      justify-content: flex-end;
      .sd-timings {
        padding-left: 10px;

        h3 {
          padding-bottom: 0px;
        }
      }
      p {
        padding-right: 10px;
      }
    }
  }
}
@media only screen and (max-width: 420px) {
  .sd-pratyaksha-seva-div {
    overflow: hidden;
    padding: 0px 10px 0px 10px;
    flex-direction: column;
    .sd-pratyaksha-seva-inner-left {
      display: none;
    }
    .sd-pratyaksha-seva-inner-middle {
      width: 100%;
      padding: 0px 10px 0px 10px;

      // justify-content: space-between;
      .sd-pratyaksha-seva-inner-middle-top {
        justify-content: space-between;
        display: flex;
        h4 {
          font-size: 17px;
        }
        div {
          display: flex;
        }
      }
      .sd-pratyaksha-seva-inner-middle-bottom {
        div {
          h5 {
            text-align: left;
            letter-spacing: 0.3px;
            font-size: 15px;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
            -webkit-box-orient: vertical;
            line-height: 20px;
            margin-bottom: 0px;
          }
          span {
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 15px;
            font-weight: 600;
            color: #f58220;
            margin-bottom: 0px;
            img {
              height: 11px;
              margin-left: 1px;
              margin-top: -2px;
            }
          }
        }
      }
    }
    .sd-pratyaksha-seva-inner-right {
      margin-top: 10px;
      padding-right: 20px;
      width: 100%;
      border: none;
      flex-direction: row;
      justify-content: center !important;
      .sd-timings {
        padding-left: 10px;

        h3 {
          padding-bottom: 0px;
        }
      }
      p {
        padding-right: 10px;
      }
    }
  }
}
